/*pc&tablet*/
@media screen and (min-width:768px) {
.coverwrap {
    #header {
        width: 1400px;
        max-width: 98%;
        top:30px;
        left: 0;
        right: 0;
        margin:  0 auto;
        position: absolute;
        z-index: 1000;
        .logo {
            a {
                width: 240px;
                display: block;
                height: 40px;
                margin-bottom: 20px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 0 0;
            }
        }
        #gnav {
            .gnav-inner {
                ul {
                    display: flex;
                    &.sp {
                        display: none;
                    }
                    li{
                        a {
                            font-family: "Sawarabi Gothic";
                            letter-spacing: .2em;
                            padding:4px 15px;
                            display: block;
                            border-right: 1px solid #CCC;
                            transition: 0.3s;
                            &:hover {
                                color: #FFF;
                                background: #07061d;
                            }
                        }
                        &:last-child {
                             a {
                                border-right: none;
                            }                       
                        }
                    }
                }
            }
        }
    }
    &.light {
        #header {
            .logo {
                a {
                    background-image: url(../images/logo-black.png);
                }
            }
            #gnav {
            .gnav-inner {
                ul {
                    li{
                        a {
                            border-right: 1px solid #CCC;
                            transition: 0.3s;
                            &:hover {
                                color: #FFF;
                                background: #07061d;
                            }
                        }
                    }
                }
            }
            }
        }
    }
    &.dark {
        #header {
            .logo {
                a {
                    background-image: url(../images/logo.png);
                }
            }
            #gnav {
            .gnav-inner {
                ul {
                    li{
                        a {
                            border-right: 1px solid #FFF;
                            transition: 0.3s;
                            color: #FFF;
                            &:hover {
                                color:#07061d;
                                background: #FFF;
                            }
                        }
                    }
                }
            }
            }
        }
    }
}
}
/*pc only*/
@media screen and (min-width: 1025px) {
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
}
/*sp only*/

@media screen and (max-width: 767px) {
    #header {
        background: rgba(6,17,55,0.95);
        color: #FFF;
        padding: 3vw 3.5vw;
        position: relative; 
        .logo {
            width: 50vw;
            margin: 0 auto;
        }
        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 1000;
        }
    }
        #nav-toggle {
            z-index: 1005;
            position: fixed;
            top: 2vh;
            right: 3.5vw;
            height: 10vw;
            cursor: pointer;
          > div {
            position: relative;
            width: 9vw;
        }
        span {
            width: 100%;
            height: 3px;
            left: 0;
            display: block;
            background: #FFF;
            position: absolute;
            transition: transform .6s ease-in-out, top .5s ease;
            &:nth-child(1) {
              top: 0;
            }
            &:nth-child(2) {
              top: 2vh;
            }
            &:nth-child(3) {
              top: 4vh;
            }
      }
    }
    #gnav {
        opacity: 0;
        .gnav-inner {
            position: fixed;
            background: #07061d;
            width: 0;
            height: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
                ul {
                    li {
                      opacity: 0;
                      transform: translateX(200px);
                      transition:  transform .6s ease, opacity .2s ease;
                      &:nth-child(2) {
                        transition-delay: .15s;
                      }
                      &:nth-child(3) {
                        transition-delay: .3s;
                      }
                      &:nth-child(4) {
                        transition-delay: .45s;
                      }
                      &:nth-child(5) {
                        transition-delay: .6s;
                      }
                      &:nth-child(6) {
                        transition-delay: .75s;
                      }
                      &:nth-child(7) {
                        transition-delay: .9s;
                      }
                        a {
                            display: block;
                            border-bottom: 1px solid #CCC;
                            padding-top: 2vh;
                            padding-bottom: 1vh;
                            transition: color .6s ease;
                            color: #FFF;
                        }
                    }
                    &:last-child {
                        li
                        &:last-child {
                             a {
                                border: none;
                            }
                        }
                    }
                    }
                }
            }

    /* open */
    .open {
        overflow: hidden;
        height: 100vh;
        #nav-toggle span {
        background: #FFF;    
            &:nth-child(1) {
              top: 2.3vh;
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              top: 2.3vh;
              width: 0;
              left: 50%;
            }
            &:nth-child(3) {
              top: 2.3vh;
              transform: rotate(-45deg);
            }
      }
      #gnav {
          visibility: visible;
          opacity: 1;
          background: rgba(7,6,29,0.7);
            color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1000;
            transition: opacity .6s ease, visibility .6s ease;
            display: flex;
            justify-content: flex-end;
          .gnav-inner {
           z-index: 1000;
           width: 70vw;
            height: 100%;
            padding:10vh 8vw 0;
          li {
            opacity: 1;
            transform: translateX(0);
            transition:  transform 1s ease, opacity .9s ease;
          }
    }
    }
    }
}