@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.4
2009-07-27
Author: Richard Clark - http://richclarkdesign.com
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  .coverwrap #header {
    width: 1400px;
    max-width: 98%;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    z-index: 1000; }
    .coverwrap #header .logo a {
      width: 240px;
      display: block;
      height: 40px;
      margin-bottom: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0; }
    .coverwrap #header #gnav .gnav-inner ul {
      display: flex; }
      .coverwrap #header #gnav .gnav-inner ul.sp {
        display: none; }
      .coverwrap #header #gnav .gnav-inner ul li a {
        font-family: "Sawarabi Gothic";
        letter-spacing: .2em;
        padding: 4px 15px;
        display: block;
        border-right: 1px solid #CCC;
        transition: 0.3s; }
        .coverwrap #header #gnav .gnav-inner ul li a:hover {
          color: #FFF;
          background: #07061d; }
      .coverwrap #header #gnav .gnav-inner ul li:last-child a {
        border-right: none; }
  .coverwrap.light #header .logo a {
    background-image: url(../images/logo-black.png); }
  .coverwrap.light #header #gnav .gnav-inner ul li a {
    border-right: 1px solid #CCC;
    transition: 0.3s; }
    .coverwrap.light #header #gnav .gnav-inner ul li a:hover {
      color: #FFF;
      background: #07061d; }
  .coverwrap.dark #header .logo a {
    background-image: url(../images/logo.png); }
  .coverwrap.dark #header #gnav .gnav-inner ul li a {
    border-right: 1px solid #FFF;
    transition: 0.3s;
    color: #FFF; }
    .coverwrap.dark #header #gnav .gnav-inner ul li a:hover {
      color: #07061d;
      background: #FFF; } }

/*pc only*/
/*tablet only*/
/*sp only*/
@media screen and (max-width: 767px) {
  #header {
    background: rgba(6, 17, 55, 0.95);
    color: #FFF;
    padding: 3vw 3.5vw;
    position: relative; }
    #header .logo {
      width: 50vw;
      margin: 0 auto; }
    #header.fixed {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 1000; }
  #nav-toggle {
    z-index: 1005;
    position: fixed;
    top: 2vh;
    right: 3.5vw;
    height: 10vw;
    cursor: pointer; }
    #nav-toggle > div {
      position: relative;
      width: 9vw; }
    #nav-toggle span {
      width: 100%;
      height: 3px;
      left: 0;
      display: block;
      background: #FFF;
      position: absolute;
      transition: transform .6s ease-in-out, top .5s ease; }
      #nav-toggle span:nth-child(1) {
        top: 0; }
      #nav-toggle span:nth-child(2) {
        top: 2vh; }
      #nav-toggle span:nth-child(3) {
        top: 4vh; }
  #gnav {
    opacity: 0; }
    #gnav .gnav-inner {
      position: fixed;
      background: #07061d;
      width: 0;
      height: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      #gnav .gnav-inner ul li {
        opacity: 0;
        transform: translateX(200px);
        transition: transform .6s ease, opacity .2s ease; }
        #gnav .gnav-inner ul li:nth-child(2) {
          transition-delay: .15s; }
        #gnav .gnav-inner ul li:nth-child(3) {
          transition-delay: .3s; }
        #gnav .gnav-inner ul li:nth-child(4) {
          transition-delay: .45s; }
        #gnav .gnav-inner ul li:nth-child(5) {
          transition-delay: .6s; }
        #gnav .gnav-inner ul li:nth-child(6) {
          transition-delay: .75s; }
        #gnav .gnav-inner ul li:nth-child(7) {
          transition-delay: .9s; }
        #gnav .gnav-inner ul li a {
          display: block;
          border-bottom: 1px solid #CCC;
          padding-top: 2vh;
          padding-bottom: 1vh;
          transition: color .6s ease;
          color: #FFF; }
      li
#gnav .gnav-inner ul:last-child:last-child a {
        border: none; }
  /* open */
  .open {
    overflow: hidden;
    height: 100vh; }
    .open #nav-toggle span {
      background: #FFF; }
      .open #nav-toggle span:nth-child(1) {
        top: 2.3vh;
        transform: rotate(45deg); }
      .open #nav-toggle span:nth-child(2) {
        top: 2.3vh;
        width: 0;
        left: 50%; }
      .open #nav-toggle span:nth-child(3) {
        top: 2.3vh;
        transform: rotate(-45deg); }
    .open #gnav {
      visibility: visible;
      opacity: 1;
      background: rgba(7, 6, 29, 0.7);
      color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      transition: opacity .6s ease, visibility .6s ease;
      display: flex;
      justify-content: flex-end; }
      .open #gnav .gnav-inner {
        z-index: 1000;
        width: 70vw;
        height: 100%;
        padding: 10vh 8vw 0; }
        .open #gnav .gnav-inner li {
          opacity: 1;
          transform: translateX(0);
          transition: transform 1s ease, opacity .9s ease; } }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  .coverwrap #footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 20px;
    z-index: 100; }
    .coverwrap #footer .footernav ul.pc {
      display: flex; }
      .coverwrap #footer .footernav ul.pc li {
        width: 45vw;
        text-align: right; }
        .coverwrap #footer .footernav ul.pc li:first-child {
          padding-right: 50px;
          width: 55vw; }
        .coverwrap #footer .footernav ul.pc li a span {
          position: relative;
          display: inline-block;
          text-decoration: none; }
          .coverwrap #footer .footernav ul.pc li a span::after {
            position: absolute;
            bottom: 1px;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            background: #222; }
        .coverwrap #footer .footernav ul.pc li a:hover span::after {
          bottom: -1px;
          opacity: 1;
          visibility: visible; }
    .coverwrap #footer .copyright {
      font-size: 12px;
      max-width: 100%;
      width: 1400px;
      margin: 0 auto;
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      padding-left: 10px; }
  .coverwrap.dark #footer {
    bottom: 0;
    padding: 10px 0;
    background: rgba(7, 6, 29, 0.8); }
    .coverwrap.dark #footer .footernav ul.pc li a {
      color: #FFF;
      transition: 0.3s;
      padding: 8px;
      display: block; }
      .coverwrap.dark #footer .footernav ul.pc li a span::after {
        background: #FFF; }
    .coverwrap.dark #footer .copyright {
      color: #FFF;
      bottom: 18px; }
  .coverwrap.light #footer {
    bottom: 0;
    padding: 10px 0;
    background: rgba(240, 240, 240, 0.8); }
    .coverwrap.light #footer .footernav ul.pc li a {
      transition: 0.3s;
      padding: 8px;
      display: block; }
    .coverwrap.light #footer .copyright {
      bottom: 18px; }
  .coverwrap.indexpage.light #footer {
    background: none; }
  .pagetop {
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 10; }
    .pagetop a {
      width: 45px;
      height: 45px;
      display: block;
      font-size: 30px;
      border-radius: 100px;
      border: 2px solid #AAA;
      line-height: 40px;
      text-align: center;
      opacity: 0.7;
      transition: 0.3s; }
      .pagetop a i {
        color: #AAA; }
      .pagetop a:hover {
        opacity: 1; } }

/*pc only*/
@media screen and (min-width: 1025px) {
  #footer .footernav ul.pc li:last-child {
    width: 40vw !important; } }

/*tablet only*/
/*sp only*/
@media screen and (max-width: 767px) {
  .coverwrap #footer .footernav ul li a {
    display: block;
    height: 8vh;
    line-height: 8vh;
    text-align: center; }
  .coverwrap .copyright {
    text-align: center;
    font-size: 12px;
    padding: 20px 0;
    border-top: 1px solid #eee;
    margin-top: 20px; }
  .coverwrap.light #footer .footernav ul li:nth-child(even) a {
    background: #eeeeee; }
  .coverwrap.dark #footer {
    background: #171728; }
    .coverwrap.dark #footer .footernav ul li a {
      color: #FFFFFF;
      background: #171728; }
    .coverwrap.dark #footer .footernav ul li:nth-child(even) a {
      background: #07061d; }
  .coverwrap.dark .copyright {
    border-top: none;
    color: #FFF; }
  .pagetop a {
    display: none !important; } }

.flexslider {
  position: relative;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  padding: 0; }

#f1.flexslider .flex-viewport {
  max-width: 100%; }

#f1.flexslider .slides li .flex-caption h1, #f1.flexslider .slides li .flex-caption h2 {
  border-bottom: 1px solid;
  letter-spacing: 0.1em; }

#f1.flexslider .slides li .flex-caption h3 {
  letter-spacing: 0.1em; }

#f1.flexslider .slides li .flex-caption p {
  line-height: 200%;
  letter-spacing: 0.1em; }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  #f1.flexslider .slides > li {
    height: 100vh;
    display: flex !important;
    align-items: flex-start; }
    #f1.flexslider .slides > li .flex-img {
      width: 55vw !important; }
      #f1.flexslider .slides > li .flex-img .pc {
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        height: 100vh; }
      #f1.flexslider .slides > li .flex-img .sp {
        display: none !important; }
    #f1.flexslider .slides > li .flex-caption {
      width: 45vw !important;
      padding: 60px 0 0 50px !important; }
      #f1.flexslider .slides > li .flex-caption h1, #f1.flexslider .slides > li .flex-caption h2 {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 150%; }
      #f1.flexslider .slides > li .flex-caption h3 {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 160%; }
      #f1.flexslider .slides > li .flex-caption ul {
        list-style: inside disc !important;
        margin-left: 15px !important;
        margin-bottom: 20px; }
      #f1.flexslider .slides > li .flex-caption p {
        margin-bottom: 10px;
        font-size: 14px !important; }
  .flex-control-nav {
    display: none !important; }
  .flex-direction-nav {
    display: block !important; }
    .flex-direction-nav a {
      height: 50px;
      opacity: 1 !important; }
    .flex-direction-nav .flex-nav-prev .flex-prev {
      left: 1vw !important; }
      .flex-direction-nav .flex-nav-prev .flex-prev::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f053";
        font-size: 30px;
        color: #FFF; }
    .flex-direction-nav .flex-nav-next .flex-next {
      left: 52vw; }
      .flex-direction-nav .flex-nav-next .flex-next::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f054";
        font-size: 30px;
        color: #FFF; }
  .cwrap {
    z-index: 100000;
    position: fixed;
    bottom: 70px;
    left: calc(55vw + 50px);
    width: 40vw !important; }
    .cwrap ul.slides {
      width: 100% !important; }
      .cwrap ul.slides li {
        width: 16% !important;
        padding: 0.3%;
        cursor: pointer;
        transition: 0.3s; }
        .cwrap ul.slides li:hover {
          opacity: 0.7; }
    .cwrap .flex-direction-nav {
      display: none !important; } }

/*pc only*/
@media screen and (min-width: 1025px) {
  #f1.flexslider ul.slides > li .flex-caption {
    width: 40vw !important; } }

/*tablet only*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .flexslider ul.slides > li .flex-img {
    width: 50vw !important; }
  .flexslider ul.slides > li .flex-caption {
    width: 50vw !important; } }

/*sp only*/
@media screen and (max-width: 767px) {
  .flex-direction-nav {
    display: none; }
  .flex-control-nav.flex-control-paging {
    bottom: 26%;
    z-index: 999; }
    .flex-control-nav.flex-control-paging li a {
      background: #FFF;
      box-shadow: none;
      border: 1px solid #CCC; }
      .flex-control-nav.flex-control-paging li a.flex-active {
        background: #EEE; }
  .flexslider .slides .flex-img .pc {
    display: none !important; }
  .flexslider .flex-caption {
    padding: 0 4vw; }
    .flexslider .flex-caption h1, .flexslider .flex-caption h2 {
      text-align: center;
      font-size: 6vw;
      line-height: 160%;
      margin-bottom: 3vw;
      padding-bottom: 2vw; }
    .flexslider .flex-caption h3 {
      text-align: center;
      font-size: 4.3vw;
      line-height: 160%; }
    .flexslider .flex-caption ul {
      list-style: inside disc;
      margin-left: 3vw; }
      .flexslider .flex-caption ul li {
        font-size: 4.3vw; }
    .flexslider .flex-caption p {
      text-align: center;
      font-size: 4.3vw;
      line-height: 200%; } }

* {
  box-sizing: border-box; }

body, html {
  font-family: "Sawarabi Mincho";
  color: #061137;
  font-weight: normal; }

h1, h2, h3, h4, h5 {
  font-weight: normal; }

a, a:hover, a:link, a:visited, a:active {
  text-decoration: none;
  color: #2a2a2a; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

table {
  width: 100%; }
  table th {
    font-weight: normal; }

.btn {
  display: flex;
  justify-content: center; }
  .btn a {
    display: block;
    max-width: 80%;
    width: 600px;
    padding: 10px;
    text-align: center;
    background: #FFF;
    color: #061137;
    transition: 0.3s; }
    .btn a:hover {
      background: #061137;
      color: #FFF; }
  .btn.btn-dark a {
    border: 2px solid;
    background: #061137;
    color: #FFF; }
    .btn.btn-dark a:hover {
      background: #FFF;
      color: #061137; }
  .btn.narrow a {
    width: 300px; }

::-webkit-scrollbar {
  width: 10px; }

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); }

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 50, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3); }

/*mfp*/
img.mfp-img {
  padding: 0; }

.mfp-bg {
  background: #07061d;
  opacity: 0.95;
  animation: fadeIn 0.5s ease 0s 1 normal;
  -webkit-animation: fadeIn 0.5s ease 0s 1 normal; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  font-size: 50px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mfp-figure:after {
  display: none; }

.coverwrap {
  min-height: 100vh; }
  .coverwrap .coverwrap-inner:before {
    background-size: 100%;
    background-repeat: repeat-y;
    background-position: 50% 50%;
    background-attachment: fixed;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1; }
  .coverwrap.dark .coverwrap-inner:before {
    background-image: url(../images/bg/bg_blue.jpg); }
  .coverwrap.dark #main {
    color: #FFF; }
  .coverwrap.light .coverwrap-inner:before {
    background: lightgray;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, lightgray 0%, white 50%, lightgray 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, lightgray 0%, white 50%, lightgray 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, lightgray 0%, white 50%, lightgray 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3d3d3', endColorstr='#d3d3d3',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .coverwrap.indexpage.light .coverwrap-inner:before {
    background: none; }
  .coverwrap.page-jewelry.light {
    background: url(../images/bg/white01.png) no-repeat;
    background-position: 100% 0%;
    background-size: 50%;
    background-attachment: fixed; }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  .sp {
    display: none; }
  .flex-pc {
    display: flex; }
    .flex-pc.space-between {
      justify-content: space-between; }
    .flex-pc.align-center {
      align-items: center; }
    .flex-pc.column {
      flex-direction: column; }
    .flex-pc.row-reverse {
      flex-direction: row-reverse; }
    .flex-pc.column-reverse {
      flex-direction: column-reverse; }
    .flex-pc.flex-2 > div {
      width: 50%; }
      .flex-pc.flex-2 > div.flex-pc-gap {
        width: 48.5%; }
  .coverwrap.page-bridal.light {
    background: url(../images/bg/bridal-bg-pc.png) no-repeat;
    background-position: 100% 0%;
    background-size: contain;
    background-attachment: fixed; } }

/*pc only*/
@media screen and (min-width: 1025px) {
  body, html {
    font-size: 16px;
    line-height: 170%; } }

/*tablet only*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  body, html {
    font-size: 16px;
    line-height: 170%; } }

/*sp only*/
@media screen and (max-width: 767px) {
  .pc {
    display: none; }
  body, html {
    font-size: 4vw;
    line-height: 160%; }
  .coverwrap.page-jewelry.light {
    background-size: 90%; }
  .coverwrap.page-bridal.light {
    background: url(../images/bg/bridal-sp1.png) no-repeat;
    background-position: 50% 50px;
    background-size: 100%; } }

#main.page .sectionnav ul li a {
  display: block;
  color: #a7ad4b;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #a7ad4b; }

#main.page .content {
  max-width: 100%;
  margin: 0 auto; }
  #main.page .content p {
    line-height: 200%;
    margin-bottom: 30px; }
    #main.page .content p.mb40 {
      margin-bottom: 40px; }
    #main.page .content p.mb60 {
      margin-bottom: 60px; }
  #main.page .content .section {
    margin-bottom: 100px; }

#main.page .title-main {
  text-align: center;
  margin: 0px 0 60px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  letter-spacing: 0.1em;
  font-weight: normal;
  font-size: 30px; }
  #main.page .title-main.title-white {
    background-image: url(../images/title/white.png);
    padding: 100px 10px 80px; }
  #main.page .title-main.title-black {
    color: #2a2a2a;
    background-image: url(../images/title/black.png);
    font-size: 40px;
    padding: 110px 10px 60px; }

#main.page .title-sub {
  font-size: 26px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.1em;
  margin: 40px 0 40px;
  padding-bottom: 15px; }
  #main.page .title-sub.bd-w {
    background: url(../images/title/border-w.png) no-repeat;
    background-size: 100%;
    background-position: bottom; }
  #main.page .title-sub.bd-s-w {
    border-bottom: 1px solid #FFF; }
  #main.page .title-sub.text-left {
    text-align: left; }

#main.page .title-round-w {
  border: 1px solid #FFF;
  border-radius: 100px;
  font-size: 20px;
  padding: 20px;
  margin: 40px auto 30px;
  max-width: 100%;
  width: 500px;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: normal; }
  #main.page .title-round-w:first-letter {
    font-size: 30px; }

#main.page.plane {
  color: #2a2a2a; }
  #main.page.plane h1 {
    letter-spacing: 0.1em;
    font-size: 24px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-weight: normal; }

#main.page.jewelry .jewelry-list ul {
  list-style: none; }
  #main.page.jewelry .jewelry-list ul li a {
    display: block;
    text-align: center;
    overflow: hidden; }
    #main.page.jewelry .jewelry-list ul li a img {
      display: block;
      margin: 0 auto 30px;
      width: 200px;
      max-width: 60%; }
    #main.page.jewelry .jewelry-list ul li a h2 {
      word-break: keep-all; }

#main.page.jewelry .jewelry-info {
  margin: 30px 0 60px;
  color: #292929; }
  #main.page.jewelry .jewelry-info dl dt > img {
    display: block;
    margin: 0 auto;
    width: 500px;
    max-width: 90%; }
  #main.page.jewelry .jewelry-info dl dt .mfp-img {
    font-size: 14px;
    font-family: "Sawarabi Gothic";
    color: #777; }
  #main.page.jewelry .jewelry-info dl dd h1 {
    border-bottom: 1px solid #CCC;
    font-weight: normal;
    letter-spacing: .1em; }
  #main.page.jewelry .jewelry-info dl dd .jewelry-info-nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0px auto;
    padding: 40px 0 30px;
    width: 400px;
    max-width: 100%; }
    #main.page.jewelry .jewelry-info dl dd .jewelry-info-nav li {
      width: 150px;
      max-width: 45%; }
      #main.page.jewelry .jewelry-info dl dd .jewelry-info-nav li a {
        display: block;
        border-radius: 100px;
        padding: 5px;
        font-size: 14px;
        text-align: center;
        border: 1px solid;
        color: #292929;
        transition: 0.3s; }
        #main.page.jewelry .jewelry-info dl dd .jewelry-info-nav li a:hover {
          color: #777; }

#main.page.bridal {
  color: #2a2a2a; }
  #main.page.bridal .content .bridal-rings p {
    text-align: center;
    padding: 20px 0 0;
    margin: 0; }
    #main.page.bridal .content .bridal-rings p strong {
      font-weight: normal;
      font-size: 20px;
      margin-right: 10px; }
  #main.page.bridal .content h2 {
    letter-spacing: 0.1em;
    font-size: 24px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  #main.page.bridal .btn {
    padding: 40px 0 100px; }

#main.page.terms .tms {
  height: 60vh;
  overflow-y: scroll; }

#main.page.terms .policy {
  margin-top: 100px; }
  #main.page.terms .policy h2 {
    font-size: 22px;
    margin-bottom: 10px; }

#main.page.company .company-info p {
  letter-spacing: 0.1em;
  border-left: 4px solid;
  padding: 8px 15px; }

#main.page.contact {
  color: #FFF; }
  #main.page.contact h1 span {
    color: #a7ad4b;
    font-size: 14px;
    margin-left: 10px; }
  #main.page.contact .contactform sup {
    color: #a7ad4b;
    margin-left: 3px; }
  #main.page.contact .contactform table {
    letter-spacing: .1em; }
    #main.page.contact .contactform table tr th {
      vertical-align: middle; }
  #main.page.contact .contactform .form-option {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: .1em; }
    #main.page.contact .contactform .form-option .form-option-inner {
      font-size: 14px; }
      #main.page.contact .contactform .form-option .form-option-inner a {
        color: #FFF;
        text-decoration: underline;
        transition: 0.3s; }
        #main.page.contact .contactform .form-option .form-option-inner a:hover {
          opacity: 0.7; }

#main.page.thanks {
  color: #FFF;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  #main.page {
    padding-top: 150px; }
    #main.page.about .about-intro {
      margin-bottom: 100px;
      overflow: hidden; }
    #main.page.jewelry .jewelry-list ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -30px; }
      #main.page.jewelry .jewelry-list ul li {
        width: calc(100% / 3);
        padding: 40px 30px; }
        #main.page.jewelry .jewelry-list ul li a {
          transition: 0.3s; }
          #main.page.jewelry .jewelry-list ul li a:hover {
            opacity: 0.7; }
    #main.page.jewelry .jewelry-info dl {
      display: flex; }
      #main.page.jewelry .jewelry-info dl dt {
        width: 40%; }
      #main.page.jewelry .jewelry-info dl dd {
        width: 60%; }
        #main.page.jewelry .jewelry-info dl dd h1 {
          font-size: 24px;
          margin-bottom: 20px;
          padding-bottom: 10px; }
    #main.page.bridal .content .titile-wrap {
      background: url(../images/bridal/pc-top.jpg) no-repeat;
      height: 400px;
      background-size: 100%;
      background-position: 50% 50%;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      position: relative; }
      #main.page.bridal .content .titile-wrap .title-main.title-black {
        height: 200px;
        margin: 0 0 0 12%;
        width: 320px; }
    #main.page.contact .contactwrap {
      display: flex;
      justify-content: space-between;
      align-content: center; }
      #main.page.contact .contactwrap .contactform {
        width: 730px;
        max-width: 80%; }
        #main.page.contact .contactwrap .contactform table tr th {
          padding: 10px 4px;
          width: 20%;
          font-size: 14px; }
        #main.page.contact .contactwrap .contactform table tr td {
          font-size: 13px;
          text-align: right;
          padding: 10px 4px; }
          #main.page.contact .contactwrap .contactform table tr td:first-child {
            width: 10%; }
          #main.page.contact .contactwrap .contactform table tr td.narrow {
            width: 8%; }
      #main.page.contact .contactwrap .contact-img {
        background: url(../images/bg/whitering.png) no-repeat;
        background-position: 100% 50%;
        background-size: 100%;
        width: calc(100% - 750px); }
    #main.page .sectionnav ul {
      display: flex;
      margin-bottom: 40px; }
      #main.page .sectionnav ul li {
        padding: 0 20px 0 15px;
        position: relative; }
        #main.page .sectionnav ul li:after {
          position: absolute;
          top: 0;
          right: 0px;
          content: "/";
          color: #a7ad4b; }
        #main.page .sectionnav ul li:last-child:after {
          display: none; }
        #main.page .sectionnav ul li a {
          transition: 0.3s; }
          #main.page .sectionnav ul li a:hover {
            opacity: 0.7; }
    #main.page .content {
      width: 1200px; }
      #main.page .content .img-and-text {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px; }
        #main.page .content .img-and-text dt {
          width: 30%; }
        #main.page .content .img-and-text dd {
          width: 68%; } }

/*pc only*/
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  #main h1 {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto; } }

/*sp only*/
@media screen and (max-width: 767px) {
  #main.page {
    margin-top: 4vh; }
    #main.page .sectionnav {
      padding: 0 4vw 10vw; }
      #main.page .sectionnav ul li {
        padding-bottom: 5px; }
        #main.page .sectionnav ul li a {
          display: inline;
          letter-spacing: 0.1em; }
    #main.page .title-main {
      font-size: 20px;
      padding: 60px 10px 40px;
      margin: 0 auto 30px;
      width: 90%;
      line-height: 170%; }
    #main.page .title-sub {
      font-size: 18px;
      margin: 0 0 30px;
      padding-bottom: 10px;
      padding-top: 40px; }
    #main.page .title-round-w {
      width: 80%;
      font-size: 15px;
      padding: 8px;
      margin-top: 60px; }
      #main.page .title-round-w:first-letter {
        font-size: 20px; }
    #main.page .content {
      letter-spacing: 0.1em; }
      #main.page .content p {
        font-size: 15px; }
      #main.page .content .sp-mb20 {
        margin-bottom: 20px; }
      #main.page .content .img-and-text dt img {
        display: block;
        max-width: 80%;
        width: 600px;
        height: auto;
        margin: 0 auto 20px; }
      #main.page .content .section {
        padding-left: 5vw;
        padding-right: 5vw; }
        #main.page .content .section.anchor {
          margin-top: -140px;
          padding-top: 140px; }
    #main.page.terms .tms {
      height: 70vh; }
      #main.page.terms .tms .policy {
        margin-top: 60px; }
        #main.page.terms .tms .policy h2 {
          font-size: 18px; }
    #main.page.bridal .title-main {
      width: 80%; }
    #main.page.bridal .content h2 {
      font-size: 20px; }
    #main.page.bridal .content .bridal-sp-bg {
      background: url(../images/bg/bridal-bg-sp.png) no-repeat;
      background-size: contain; }
    #main.page.bridal .btn {
      padding: 20px 0 0; }
    #main.page.jewelry .title-main {
      width: 80%; }
    #main.page.jewelry .jewelry-list ul li a img {
      border-top: 1px solid;
      padding-top: 50px;
      margin: 50px auto 10px;
      width: 200px; }
    #main.page.jewelry .jewelry-list ul li:first-child a img {
      border: none;
      margin: 0 auto;
      padding: 0; }
    #main.page.jewelry .jewelry-info dl dt .mfp-img {
      padding: 20px 10px;
      text-align: center;
      display: block; }
    #main.page.jewelry .jewelry-info dl dd h1 {
      font-size: 18px;
      margin-bottom: 20px;
      padding-bottom: 10px;
      text-align: center; }
    #main.page.contact .contactform table tr th {
      display: block;
      text-align: left;
      width: 100%;
      font-size: 15px;
      height: 44px;
      line-height: 44px; }
    #main.page.contact .contactform table tr td {
      display: block;
      width: 100%; }
      #main.page.contact .contactform table tr td input {
        margin: 5px 0 10px; }
    #main.page.contact .contactform table tr.above {
      width: 48%;
      float: left; }
    #main.page.contact .contactform table tr.bottom {
      width: 48%;
      padding-top: 44px;
      float: right; }
    #main.page.contact .contactform .form-option .form-option-inner {
      font-size: 3.2vw; } }

input[type="text"], input[type="email"], input[type="tel"], textarea, select {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  background: #FFF;
  font-weight: bold; }

select {
  border: 0;
  outline: 1px solid #FFF;
  outline-offset: -1px; }

input[type="submit"] {
  background: #5153a3;
  color: #FFF;
  padding: 10px;
  border-radius: 0;
  font-size: 14px;
  border: none;
  width: 300px;
  max-width: 80%;
  display: block;
  margin: 20px auto;
  letter-spacing: .1em;
  transition: 0.3s;
  font-family: "Sawarabi Mincho";
  cursor: pointer; }
  input[type="submit"]:hover {
    background: #FFF;
    color: #5153a3; }

/*pc&tablet*/
@media screen and (min-width: 768px) {
  input[type="text"], input[type="email"], input[type="tel"], textarea, select {
    font-size: 13px;
    padding: 6px 10px; }
  select {
    height: 28px;
    line-height: 28px; } }

/*pc only*/
/*tablet only*/
/*sp only*/
@media screen and (max-width: 767px) {
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
    vertical-align: middle;
    text-align: center; }
  input[type="text"], input[type="email"], input[type="tel"], textarea, select {
    font-size: 16px;
    padding: 6px 10px; }
  input[type="text"], input[type="email"], input[type="tel"] {
    line-height: 16px; }
  select {
    height: 30px;
    line-height: 30px; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder,
  select::-webkit-input-placeholder {
    padding-top: 0.1em; } }
