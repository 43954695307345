* {
    box-sizing: border-box;
}
body,html { 
    font-family: "Sawarabi Mincho"; 
    color: #061137;
    font-weight: normal;
}
h1,h2,h3,h4,h5 {
    font-weight: normal;
}
a,a:hover,a:link,a:visited,a:active {
    text-decoration: none;
    color: #2a2a2a;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
table {
    width: 100%;
    th {
        font-weight: normal;
    }
}
.btn {
    display: flex;
    justify-content: center;
    a {
        display: block;
        max-width: 80%;
        width: 600px;
        padding: 10px;
        text-align: center;
        background: #FFF;
        color: #061137;
        transition: 0.3s;
        &:hover {
            background:#061137;
            color: #FFF;
        }
    }
    &.btn-dark {
        a {
            border: 2px solid;
            background:#061137;
            color: #FFF;
            &:hover {
                background:#FFF;
                color: #061137;
            }
        }
    }
    &.narrow {
        a {
            width: 300px;
        }
    }
}
::-webkit-scrollbar {
    width: 10px;
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 50, .5);
  border-radius: 10px;
  box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
}

/*mfp*/
img.mfp-img {
    padding: 0;
}
.mfp-bg {
	background: #07061d;;
	opacity:0.95;
    animation: fadeIn 0.5s ease 0s 1 normal;
    -webkit-animation: fadeIn 0.5s ease 0s 1 normal;
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    font-size: 50px;
}
@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}
.mfp-figure:after {
    display: none;
}
.coverwrap {
    min-height: 100vh;
    .coverwrap-inner:before {
        background-size: 100%;
        background-repeat: repeat-y;
        background-position: 50% 50%;
        background-attachment: fixed;
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        }
        &.dark {
            .coverwrap-inner:before {
            background-image:url(../images/bg/bg_blue.jpg);
            }
            #main {
                color: #FFF;
            }
        }
        &.light {
        .coverwrap-inner:before {
        background: rgb(211,211,211); /* Old browsers */
        background: -moz-linear-gradient(-45deg, rgba(211,211,211,1) 0%, rgba(255,255,255,1) 50%, rgba(211,211,211,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, rgba(211,211,211,1) 0%,rgba(255,255,255,1) 50%,rgba(211,211,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, rgba(211,211,211,1) 0%,rgba(255,255,255,1) 50%,rgba(211,211,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3d3d3', endColorstr='#d3d3d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        }
        &.indexpage.light {
            .coverwrap-inner:before {
                background: none;
            }
        }
        &.page-jewelry.light {
            background: url(../images/bg/white01.png) no-repeat;
            background-position: 100% 0%;
            background-size: 50%;
            background-attachment: fixed;
        }
}
/*pc&tablet*/
@media screen and (min-width:768px) {
    .sp {
        display: none;
    }
    .flex-pc {
        display: flex;
        &.space-between {
             justify-content: space-between;
        }
        &.align-center {
            align-items: center;
        }
        &.column {
            flex-direction: column;
        }
        &.row-reverse {
            flex-direction: row-reverse;
        }
        &.column-reverse {
            flex-direction: column-reverse;
        }
        &.flex-2 > div {
            width: 50%;
            &.flex-pc-gap {
                width: 48.5%;
            }
        }
    }
    .coverwrap {
        &.page-bridal.light {
            background: url(../images/bg/bridal-bg-pc.png) no-repeat;
            background-position: 100% 0%;
            background-size: contain;
            background-attachment: fixed;
        }
    }
}
/*pc only*/
@media screen and (min-width: 1025px) {
 body,html {    
    font-size: 16px;
    line-height: 170%;
    }
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
 body,html {    
    font-size: 16px;
    line-height: 170%;
    }
}
/*sp only*/
@media screen and (max-width: 767px) {
    .pc {
        display: none;
    }
     body,html {    
        font-size: 4vw;
        line-height: 160%;
    }
    .coverwrap {
            &.page-jewelry.light {
                background-size: 90%;
            }
            &.page-bridal.light {
                background: url(../images/bg/bridal-sp1.png) no-repeat;
                background-position: 50% 50px;
                background-size: 100%;
            }
    }
}