#main {
    &.page {
        .sectionnav {
            ul {
             li {
                a {
                    display: block;
                     color: #a7ad4b;
                     padding-bottom: 0px;
                     margin-bottom: 10px;
                     border-bottom: 1px solid #a7ad4b;
                    }
                }   
            }
        }
        .content {
            max-width: 100%;
            margin: 0 auto;
            p {
                line-height: 200%;
                margin-bottom: 30px;
                &.mb40 {
                    margin-bottom: 40px;
                }
                &.mb60 {
                    margin-bottom: 60px;
                }
            }
            .section {
                margin-bottom: 100px;
            }
        }
        .title-main {
            text-align: center;
            margin:  0px 0 60px;
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            letter-spacing: 0.1em;
            font-weight: normal;
            font-size: 30px;
            &.title-white {
                background-image: url(../images/title/white.png);
                padding: 100px 10px 80px;
            }
            &.title-black {
            color: #2a2a2a;
                background-image: url(../images/title/black.png);
                font-size: 40px;
                padding: 110px 10px 60px;
            }
        }
        .title-sub {
            font-size: 26px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.1em;
            margin: 40px 0 40px;
            padding-bottom: 15px;
           &.bd-w {
               background:url(../images/title/border-w.png) no-repeat;
               background-size: 100%;
               background-position: bottom;
           }
           &.bd-s-w {
               border-bottom: 1px solid #FFF;
           }
           &.text-left {
               text-align: left;
           }
        }
        .title-round-w {
            border: 1px solid #FFF;
            border-radius: 100px;
            font-size: 20px;
            padding: 20px;
            margin: 40px auto 30px;
            max-width: 100%;
            width: 500px;
            letter-spacing: 0.1em;
            text-align: center;
            font-weight: normal;
            &:first-letter {
                 font-size: 30px;   
            }
        }
        &.plane {
            color: #2a2a2a;
            h1 {
                letter-spacing: 0.1em;
                font-size: 24px;
                border-bottom: 1px solid #CCC;
                margin-bottom: 30px;
                padding-bottom: 10px;
                font-weight: normal;
            }
        }
        &.jewelry {
            .jewelry-list {
                ul {
                    list-style: none;
                    li {
                        a {
                            display: block;
                            text-align: center;
                            overflow: hidden;
                            img {
                                display: block;
                                margin: 0 auto 30px;
                                width: 200px;
                                max-width: 60%;
                            }
                            h2 {
                                word-break: keep-all;
                            }
                        }
                    }
                }
            }
            .jewelry-info {
                    margin: 30px 0 60px;
                    color: #292929;
                dl {
                    dt {
                        > img {
                            display: block;
                            margin: 0 auto;
                            width: 500px;
                            max-width: 90%;
                        }
                        .mfp-img {
                            font-size: 14px;
                            font-family: "Sawarabi Gothic";
                            color: #777;
                        }
                    }
                    dd {
                        h1 {
                            border-bottom: 1px solid #CCC;
                            font-weight: normal;
                            letter-spacing: .1em;
                        }
                        .jewelry-info-nav {
                            list-style: none;
                            display: flex;
                            justify-content: space-around;
                            margin: 0px auto;
                            padding: 40px 0 30px;
                            width: 400px;
                            max-width: 100%;
                            li {
                                width:150px;
                                max-width: 45%;
                                a {
                                    display: block;
                                    border-radius: 100px;
                                    padding: 5px;
                                    font-size: 14px;
                                    text-align: center;
                                    border: 1px solid;
                                    color: #292929;
                                    transition: 0.3s;
                                    &:hover {
                                        color: #777;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.bridal {
            color: #2a2a2a;
            .content {
                .bridal-rings p {
                    text-align: center;
                    padding: 20px 0 0;
                    margin: 0;
                    strong {
                        font-weight: normal;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }
                h2 {
                        letter-spacing: 0.1em;
                        font-size: 24px;
                        border-bottom: 1px solid #CCC;
                        margin-bottom: 20px;
                        padding-bottom: 10px;
                    }
                }
            .btn {
                padding:40px 0 100px;
            }
        }
        &.terms {
            .tms {
                height: 60vh;
                overflow-y: scroll;
                }
            .policy {
                margin-top: 100px;
                h2 {
                  font-size: 22px;
                  margin-bottom: 10px;
                }
            }
            }
        &.company {
            .company-info {
            p {
            letter-spacing: 0.1em;
                border-left: 4px solid;
                padding: 8px 15px;
                }
            }
        }
        &.contact {
            color: #FFF;
            h1 {
                span {
                    color: #a7ad4b;
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
            .contactform {
                sup {
                    color: #a7ad4b;
                    margin-left: 3px;
                }
                table {
                    letter-spacing: .1em;
                    tr {
                        th {
                            vertical-align: middle;
                        }
                        td {
                        }
                    }
                }
                .form-option {
                    margin: 20px auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    letter-spacing: .1em;
                    .form-option-inner {
                        font-size: 14px;
                        a {
                            color: #FFF;
                            text-decoration: underline;
                            transition: 0.3s;
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
        &.thanks {
            color: #FFF;
            height:80vh;
            display:flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}

/*pc&tablet*/
@media screen and (min-width:768px) {
    #main {
        &.page {
            &.about {
                .about-intro {
                    margin-bottom: 100px;
                    overflow: hidden;
                }
            }
            &.jewelry {
                .jewelry-list {
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -30px;
                        li {
                            width: calc(100% / 3);
                            padding: 40px 30px;
                            a {
                            transition: 0.3s;
                            &:hover {
                                opacity: 0.7;
                            }
                            }
                        }
                    }
                }
                .jewelry-info {
                    dl {
                        display: flex;
                        dt {
                            width: 40%;

                        }
                        dd {
                            width: 60%;                            
                            h1 {
                                font-size: 24px;
                                margin-bottom: 20px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
            &.bridal {
                .content {
                    .titile-wrap {
                        background: url(../images/bridal/pc-top.jpg) no-repeat;
                        height: 400px;
                        background-size: 100%;
                        background-position: 50% 50%;
                        margin-bottom: 40px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        .title-main {
                            &.title-black {
                                height: 200px;
                                margin: 0 0 0 12%;
                                width: 320px;
                            }
                        }
                    }
                }
            }
            &.contact {
            .contactwrap {
                display: flex;
                justify-content: space-between;
                align-content: center;
                .contactform {
                    width: 730px;
                    max-width: 80%;
                    table {
                        tr {
                            th {
                                padding: 10px 4px;
                                width:20%;
                                font-size: 14px;
                            }
                            td {
                                font-size: 13px;
                                text-align: right;
                                padding: 10px 4px;
                                &:first-child {
                                    width: 10%;
                                }
                                &.narrow {
                                    width: 8%;
                                }
                            }
                        }
                    }
                }
                .contact-img {
                    background: url(../images/bg/whitering.png) no-repeat;
                    background-position: 100% 50%;
                    background-size: 100%;
                    width: calc(100% - 750px);
                }
                }
            }
        .sectionnav {
                    ul {
                    display: flex;
                    margin-bottom: 40px;
                     li {
                        padding:0 20px 0 15px;
                        position: relative;
                        &:after {
                            position: absolute;
                            top: 0;
                            right: 0px;
                            content:"/";
                            color: #a7ad4b;
                            }
                            &:last-child {
                                &:after {
                                    display: none;
                                } 
                            }
                                a {
                                    transition: 0.3s;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }   
                    }
                }
            padding-top: 150px;
            .content {
                width: 1200px;
                .img-and-text {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 30px;
                    dt {
                        width: 30%;
                    }
                    dd {
                        width: 68%;
                    }
                }
            }
            
        }
    }
}
/*pc only*/
@media screen and (min-width: 1025px) {
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
            #main {
                h1 {
                max-width: 98%;
                margin-left: auto;
                margin-right: auto;
                }
            }
}
/*sp only*/
@media screen and (max-width: 767px) {
#main {
     &.page {
         margin-top: 4vh;
        .sectionnav {
            padding:0 4vw 10vw;
                ul{
                     li {
                         padding-bottom: 5px;
                        a {
                            display: inline;
                            letter-spacing: 0.1em;
                        }
                    }   
                    }
            }
            .title-main {
                font-size: 20px;
                padding: 60px 10px 40px;
                margin: 0 auto 30px;
                width: 90%;
                line-height: 170%;
                }
            .title-sub {
                font-size: 18px;
                margin:0 0 30px;
                padding-bottom:10px;
                padding-top: 40px;
            }
            .title-round-w {
                width: 80%;
                font-size: 15px;
                padding: 8px;
                margin-top: 60px;
                 &:first-letter {
                                 font-size: 20px;
                            }
            }
            .content {
                p {
                    font-size: 15px;
                }
                .sp-mb20 {
                    margin-bottom: 20px;
                }
                letter-spacing: 0.1em;
                .img-and-text {
                    dt {
                        img {
                            display: block;
                            max-width: 80%;
                            width: 600px;
                            height: auto;
                            margin: 0 auto 20px;
                        }
                    }
                    dd {
                    }
                }
                .section {
                    padding-left: 5vw;
                    padding-right: 5vw;
                    &.anchor {
                        margin-top: -140px;
                        padding-top: 140px;
                    }
                }
            }
    &.terms {
             .tms {
                 height: 70vh;
             .policy {
                margin-top: 60px;
                h2 {
                  font-size: 18px;
                }
                }
            }
            }  
    &.bridal {
        .title-main {
                width: 80%;
        }
        .content {
            h2 {
                font-size: 20px;
            }
            .bridal-sp-bg {
                background: url(../images/bg/bridal-bg-sp.png) no-repeat;
                background-size: contain;
            }
        }
        .btn {
                padding:20px 0 0;
            }
    }
    &.jewelry {
        .title-main {
                width: 80%;
        }
            .jewelry-list {
                ul {
                    li {
                        a {
                            img {
                               border-top: 1px solid;
                                padding-top: 50px;
                                margin: 50px auto 10px;
                                width: 200px;
                            }
                        }
                        &:first-child {
                            a {
                                img {
                                border: none;
                                margin: 0 auto;
                                padding: 0;
                                }
                            }
                        }
                    }
                }
            }
            .jewelry-info {
                dl {
                    dt {
                    .mfp-img {
                        padding: 20px 10px;
                        text-align: center;
                        display: block;
                        }
                    }
                    dd {
                        h1 {
                            font-size: 18px;
                            margin-bottom: 20px;
                            padding-bottom: 10px;
                            text-align: center;
                        }
                    }
                }
            }
            }
        &.contact {
            .contactform {
                table {
                    tr {
                        th {
                            display: block;
                            text-align: left;
                            width: 100%;
                            font-size: 15px;
                            height: 44px;
                            line-height: 44px;
                        }
                        td {
                            display: block;
                            width: 100%;
                            input {
                                margin:5px 0 10px;
                            }
                        }
                        &.above {
                            width: 48%;
                            float: left;
                        }
                        &.bottom {
                            width: 48%;
                            padding-top: 44px;
                            float: right;
                        }
                    }
                }
                .form-option {
                    .form-option-inner {
                        font-size: 3.2vw;
                    }
                }
            }
        }
        }
        }
}