/*pc&tablet*/
@media screen and (min-width:768px) {
    .coverwrap {
       #footer {
           width: 100%;
            position: fixed;
            left: 0;
            bottom: 20px;
            z-index: 100;
           .footernav ul.pc {
               display: flex;
               li {
               width: 45vw;
               text-align: right;
               &:first-child {
                   padding-right: 50px;
                   width: 55vw;
                }
                a {
                    span {
                         position: relative;
                          display: inline-block;
                          text-decoration: none;
                          &::after {
                          position: absolute;
                          bottom: 1px;
                          left: 0;
                          content: '';
                          width: 100%;
                          height: 1px;
                          opacity: 0;
                          visibility: hidden;
                          transition: .3s;
                          background:#222 ;
                        }
                    }
                    &:hover {
                        span::after {
                            bottom: -1px;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
               }
           }
           .copyright {
               font-size: 12px;
               max-width: 100%;
               width: 1400px;
               margin: 0 auto;
               bottom: 0;
               position: absolute;
               left:0;
               right:0;
               z-index: -1;
               padding-left: 10px;
           }
       }
    &.dark {
        #footer {
            bottom: 0;
            padding: 10px 0;
            background:rgba(7, 6, 29, 0.8);
           .footernav ul.pc {
               li {
                   a {
                       color: #FFF;
                       transition: 0.3s;
                        padding:8px;
                        display: block;
                        span {
                            &::after {
                              background: #FFF;
                            }
                        }
                       &:hover {
                       }
                   }
               }
           }
           .copyright {
               color: #FFF;
               bottom: 18px;
           }
       }
    }
    &.light {
        #footer {
            bottom: 0;
            padding: 10px 0;
            background:rgba(240,240,240,0.8);
           .footernav ul.pc {
               li {
                   a {
                       transition: 0.3s;
                        padding:8px;
                        display: block;
                   }
               }
           }
           .copyright {
               bottom: 18px;
           }
       }
    }
    &.indexpage.light {
        #footer {
             background:none;
        }
    }
    }
    .pagetop {
        position: fixed;
        right: 20px;
        bottom: 80px;
        z-index: 10;
        a {
            width: 45px;
            height: 45px;
            display: block;
            font-size: 30px;
            border-radius: 100px;
            border: 2px solid #AAA;
            line-height: 40px;
            text-align: center;
            opacity: 0.7;
            transition: 0.3s;
            i {
                color: #AAA;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
}
/*pc only*/
@media screen and (min-width: 1025px) {
   #footer {
       .footernav ul.pc {
           li:last-child {
           width: 40vw!important;
        }
        }
    }
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
}
/*sp only*/
@media screen and (max-width: 767px) {
    .coverwrap {
        #footer {
            .footernav ul {
                li {
                    a {
                        display: block;
                        height: 8vh;
                        line-height: 8vh;
                        text-align: center;
                    }
                    }
                }
            }
            .copyright {
                text-align: center;
                font-size: 12px;
                padding: 20px 0;
                border-top: 1px solid #eee;
                margin-top: 20px;
            }
        &.light {
        #footer {
            .footernav ul {
                li {
                    &:nth-child(even) {
                        a {
                            background: #eeeeee;
                        }
                    }
                }
                }
            }
        }
        &.dark {
            #footer {
                background: #171728;
                .footernav ul {
                    li {
                        a {
                            color: #FFFFFF;
                            background: #171728;
                        }
                        &:nth-child(even) {
                            a {
                                background: #07061d;
                            }
                        }
                    }
                    }
                }
                .copyright {
                    border-top:none;
                    color: #FFF;
                }
            }
    }
    .pagetop a {
        display: none!important;
        }
}