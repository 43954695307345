.flexslider {
    position: relative;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    padding: 0;
}
#f1.flexslider {
.flex-viewport {
    max-width: 100%;
}
.slides {
    li {
    .flex-caption {
    h1,h2 {
        border-bottom: 1px solid;
        letter-spacing:0.1em;
    }
    h3 {
        letter-spacing:0.1em;
    }
    p {
        line-height: 200%;
        letter-spacing:0.1em;
    }
    }
}
}
}
/*pc&tablet*/
@media screen and (min-width:768px) {
    #f1.flexslider {
        .slides {
                > li {
                    height: 100vh;
                    display: flex!important;
                    align-items: flex-start;
                    .flex-img {
                        width: 55vw!important;
                        .pc {
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-size: cover;
                            height: 100vh;
                        }
                        .sp { 
                            display: none!important
                        }
                    }
                    .flex-caption {
                        width:45vw!important;
                        padding:60px 0 0 50px!important;
                        h1,h2 {
                            font-size: 30px;
                            margin-bottom: 20px;
                            line-height: 150%;
                        }
                        h3 {
                            font-size: 20px;
                            margin-bottom: 20px;
                            line-height: 160%;
                        }
                        ul {
                            list-style:inside disc!important;
                            margin-left: 15px!important;
                            margin-bottom: 20px;
                        }
                        p {
                            margin-bottom: 10px;
                            font-size: 14px!important;
                        }
                    }
                }
            }
        }
        .flex-control-nav {
              display: none!important;
       }
       .flex-direction-nav {
            display: block!important;
            a {
                height: 50px;
                opacity: 1!important;
            }
            .flex-nav-prev {
            .flex-prev {
                left: 1vw!important;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f053";
                    font-size: 30px;
                    color:#FFF;
                }
            }
            }
            .flex-nav-next {
           .flex-next {
                left: 52vw;
                 &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f054";
                    font-size: 30px;
                    color:#FFF;
                }
            }
            }
        }
        .cwrap {
            z-index: 100000;
            position: fixed;
            bottom: 70px;
            left: calc(55vw + 50px);
            width: 40vw!important;
            ul.slides {
                width: 100%!important;
                li {
                    width: 16%!important;
                    padding: 0.3%;
                    cursor: pointer;
                    transition: 0.3s;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .flex-direction-nav {
                display: none!important;
            }
        }
}
/*pc only*/
@media screen and (min-width: 1025px) {
#f1.flexslider {
        ul.slides {
                > li {
                    .flex-caption {
                        width:40vw!important;
                    }
                }
        }
    }
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
 .flexslider {
        ul.slides {
                > li {
                    .flex-img {
                        width: 50vw!important;
                        }
                    .flex-caption {
                        width:50vw!important;
                    }
                }
        }
    }
}
/*sp only*/
@media screen and (max-width: 767px) {
.flex-direction-nav {
    display: none;
}
.flex-control-nav {
    &.flex-control-paging {
    bottom: 26%;
    z-index: 999;
        li a {
            background: #FFF;
            box-shadow: none;
            border:1px solid #CCC;
            &.flex-active {
                background:#EEE;
            }
        }
    }
}
    .flexslider {
    .slides {
        .flex-img {
            .pc { 
                display: none!important
                }
        }
    }
    .flex-caption {
        padding: 0 4vw;
        h1,h2 {
            text-align: center;
            font-size: 6vw;
            line-height: 160%;
            margin-bottom: 3vw;
            padding-bottom: 2vw;
        }
        h3 {
            text-align: center;
            font-size: 4.3vw;
            line-height: 160%;
        }
        ul {
            list-style:inside disc;
            margin-left: 3vw;
            li {
                font-size: 4.3vw;
            }
        }
        p {
            text-align: center;
            font-size: 4.3vw;
            line-height: 200%;
        }
    }

}
}