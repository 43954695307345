input[type="text"],input[type="email"],input[type="tel"],textarea,select {
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    background: #FFF;
    font-weight: bold;
}
select {
    border: 0;
    outline: 1px solid #FFF;
    outline-offset: -1px;
}
input[type="submit"] {
    background: #5153a3;
    color: #FFF;
    padding: 10px;
    border-radius: 0;
    font-size: 14px;
    border: none;
    width: 300px;
    max-width: 80%;
    display: block;
    margin: 20px auto;
    letter-spacing: .1em;
    transition: 0.3s;
    font-family: "Sawarabi Mincho";
    cursor: pointer;
    &:hover {
        background: #FFF;
        color:  #5153a3;
    }
}
/*pc&tablet*/
@media screen and (min-width:768px) {
    input[type="text"],input[type="email"],input[type="tel"],textarea,select {
        font-size: 13px;
        padding: 6px 10px;
    }
    select {
        height: 28px;
        line-height: 28px;
    }
}
/*pc only*/
@media screen and (min-width: 1025px) {
}
/*tablet only*/
@media screen and (max-width: 1024px) and (min-width:768px) {
}
/*sp only*/

@media screen and (max-width: 767px) {
    input[type="submit"] {
    -webkit-appearance:none;
    border-radius:0;
    vertical-align:middle;
    text-align:center;
    }
    input[type="text"],input[type="email"],input[type="tel"],textarea,select {
        font-size: 16px;
        padding: 6px 10px;
    }
    input[type="text"],input[type="email"],input[type="tel"] {
        line-height: 16px;
    }
    select {
        height: 30px;
        line-height: 30px;
    }
    input[type="text"]::-webkit-input-placeholder,
    input[type="email"]::-webkit-input-placeholder,
    input[type="tel"]::-webkit-input-placeholder,textarea::-webkit-input-placeholder,
    select::-webkit-input-placeholder {
    padding-top: 0.1em;
    }
}